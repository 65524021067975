import { NgIf } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { StudisuConfig, getStudisuConfig } from '../../config/studisu.config';

@Component({
  selector: 'ba-studisu-signet',
  templateUrl: './signet.component.html',
  styleUrls: ['./signet.component.scss'],
  standalone: true,
  imports: [NgIf]
})
export class SignetComponent {
  @Input()
  public hasSignet = false;

  @Input()
  public set url(url: string) {
    this.loaded = false;
    this._url = url;
    // STUDISU-1294 Verbessert die Transition vom Default-Logo auf das geladene Logo
    this.imgBlock.nativeElement.style.display = 'none';
  }

  @ViewChild('imgBlock', { static: true })
  private imgBlock;

  private _url: string;
  private _banid: Number;
  private conf: StudisuConfig = getStudisuConfig();
  private serverURL: string;
  loaded = false;

  constructor() {
    this.serverURL = this.conf.studienangeboteServiceHost + this.conf.bildungsanbieterSignet;
  }

  /**
   * Event-Handler, der aufgerufen wird, wenn die Bilddaten geladen wurden.
   *
   * Dann wird der Platzhalter durch das geladene Bild ersetzt...
   */
  public loadingComplete(): void {
    this.loaded = true;
    this.imgBlock.nativeElement.style.display = 'inline';
  }

  /**
   * Liefert die URL für das Bild zurück.
   *
   * @returns {string}
   */
  public getImageUrl(): string {
    return this._url;
  }
}
