export const LAENDER_IMAGE_MAP = {
  Belgien: ['be-01.png', 'Belgien'],
  Dänemark: ['dk-01.png', 'Dänemark'],
  Deutschland: ['de-01.png', 'Deutschland'],
  England: ['gb-eng-01.png', 'England'],
  Finnland: ['fi-01.png', 'Finnland'],
  Frankreich: ['fr-01.png', 'Frankreich'],
  Italien: ['it-01.png', 'Italien'],
  Irland: ['ie-01.png', 'Irland'],
  Niederlande: ['nl-01.png', 'Niederlande'],
  Norwegen: ['no-01.png', 'Norwegen'],
  Österreich: ['at-01.png', 'Österreich'],
  Polen: ['pl-01.png', 'Polen'],
  Portugal: ['pt-01.png', 'Portugal'],
  Schottland: ['gb-sct-01.png', 'Schottland'],
  Schweden: ['se-01.png', 'Schweden'],
  Schweiz: ['ch-01.png', 'Schweiz'],
  Spanien: ['es-01.png', 'Spanien'],
  Tschechien: ['cz-01.png', 'Tschechien'],
  Luxemburg: ['lu-01.png', 'Luxemburg'],
  Liechtenstein: ['li-01.png', 'Liechtenstein'],
  Ungarn: ['hu-01.png', 'Ungarn']
};
