import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  // speichert immer das zuletzt angesehene Angebot für korrektes Fokus- und Scrollverhalten
  private _letztesAngebot$ = new ReplaySubject<number | undefined>(1);

  public letztesAngebot$ = this._letztesAngebot$.asObservable();

  // speichert gewählte Seite für korrektes Fokus- und Scrollverhalten, Subject wegen Nutzung in onInit
  private seiteSubject$: Subject<void> = new Subject<void>();
  public seitePushed$: Observable<void> = this.seiteSubject$.asObservable();

  public setLetztesAngebot(angebotsId: number) {
    this._letztesAngebot$.next(angebotsId);
  }

  public clearLetztesAngebot() {
    this._letztesAngebot$.next(undefined);
  }

  public triggerSeite() {
    this.seiteSubject$.next();
  }
}
