import { Injectable } from '@angular/core';
import { LAENDER_IMAGE_MAP } from '../main/detailseitenheader/laenderimagemap';

/**
 * Serviceklasse das richtige Einbinden der Flaggen bei ausländischen Angeboten.
 */

@Injectable()
export class FlaggenService {
  private LAENDER_BILD = 'assets/flags/';

  public laenderBildVerzeichnis(staat: string): String {
    // Ist das Mapping eines Staats zu einem Eintrag in der laenderimagemap.ts kompatibel
    // wird dem Staat mit [0] der Pfad zu seiner Landes-Flage bestimmt
    // und aus dem Mapping Begriff (zB OESTERREICH) ein Formatierter Name ausgegben (zB Österreich)
    if (LAENDER_IMAGE_MAP[staat] !== undefined) {
      return this.LAENDER_BILD + LAENDER_IMAGE_MAP[staat][0];
    } else {
      return null;
    }
  }

  public laenderNameFormatiert(staat: string): String {
    // Ist das Mapping eines Staats zu einem Eintrag in der laenderimagemap.ts kompatibel
    // wird dem Staat mit [0] der Pfad zu seiner Landes-Flage bestimmt
    // und aus dem Mapping Begriff (zB OESTERREICH) ein Formatierter Name ausgegben (zB Österreich)
    if (LAENDER_IMAGE_MAP[staat] !== undefined) {
      return LAENDER_IMAGE_MAP[staat][1];
    } else {
      return staat;
    }
  }
}
