import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MerklistService implements OnDestroy {
  private static readonly KEY = 'studisu_merklistitems';
  private static readonly SEPERATOR = ',';

  private merkListTerminIds$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  private sub: Subscription;

  constructor() {
    const items: string | null = window.sessionStorage.getItem(MerklistService.KEY);
    this.merkListTerminIds$.next(items?.split(MerklistService.SEPERATOR) ?? []);

    this.sub = this.merkListTerminIds$.subscribe(MerklistService.syncSessionStorage);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public getMerklist(): Observable<string[]> {
    return this.merkListTerminIds$;
  }

  public addToMerklist(item: string | number): void {
    if (!item) {
      return;
    }

    this.merkListTerminIds$.next([...this.merkListTerminIds$.value, String(item)]);
  }

  public removeFromMerklist(item: string | number): void {
    this.merkListTerminIds$.next(
      this.merkListTerminIds$.value.filter((val) => val != String(item))
    );
  }

  public clearMerkliste(): void {
    this.merkListTerminIds$.next([]);
  }

  private static syncSessionStorage(ids: string[]) {
    if (ids.length === 0) {
      window.sessionStorage.removeItem(MerklistService.KEY);
    } else {
      window.sessionStorage.setItem(MerklistService.KEY, ids.join(MerklistService.SEPERATOR));
    }
  }
}
